<!-- 活动 -->
<template>
  <div class="activity">
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="tabs">
      <van-tabs v-model="active" sticky :border="true">
        <van-tab title="当前活动">
          <div class="content">
            <div v-if="noStarList.length > 0 || list.length > 0">
              <!-- 未开始 -->
              <div v-for="(item,index) in noStarList" :key="index" class="item">
                <p class="title">名称:{{ item.activity_name }}</p>
                <div class="center">
                  <span>发起人:{{ item.sponsor_name }}</span>
                  <span class="sign_up">报名人数:{{ item.player_count }}</span>
                  <div style="margin:10px 0;">
                    <span>讲师积分:{{ item.speaker_credits }}</span>
                    <span class="sign_up">讲师学分:{{ item.speaker_score }}</span>
                  </div>
                  <div style="margin:10px 0;">
                    <span style="margin-left:0;">员工积分:{{ item.player_credits }}</span>
                    <span class="sign_up">员工学分:{{ item.player_score }}</span>
                  </div>
                  <p class="start_time">开始时间:{{ item.start_time }}</p>
                  <p class="address">地址:{{ item.activity_space }}</p>
                </div>
                <!-- <button v-if="item.is_join === 0" class="btn" @click="join(item)">报名</button>
                <button v-else class="btn bg">已报名</button> -->
                <template v-if="(item.status == 0)">
                  <!-- 未开始 -->
                  <template v-if="(item.player_count < item.max_number)||item.max_number==0">
                    <!-- 未满 -->
                    <template v-if="(item.is_join !== 0 || item.is_baoming !== 0)">
                      <!-- 已报名 -->
                      <span class="btn_gray">已报名</span>
                    </template>
                    <template v-else>
                      <!-- 未报名 -->
                      <span class="btn_red" @click="join(item)">报名</span>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 名额已满 -->
                    <span class="btn_gray">名额已满</span>
                  </template>
                </template>
                <template v-else>
                  <!-- 进行中、已结束 -->
                  <template v-if="(item.player_count < item.max_number)||item.max_number==0">
                    <!-- 未满 -->
                    <template v-if="(item.is_join === 1 || item.is_baoming === 1)">
                      <!-- 已报名 -->
                      <span v-if="(item.signin_status == 1)" class="btn_gray">已签到</span>
                      <span v-else class="btn_gray">未签到</span>
                    </template>
                    <template v-else>
                      <!-- 未报名 -->
                      <span class="btn_gray">未报名</span>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 名额已满 -->
                    <span class="btn_gray">名额已满</span>
                  </template>
                </template>
                <span class="statu_t">{{ item.status == 0 ? '活动未开始' : (item.status == 1 ? '活动进行中' : '活动已结束') }}</span>
              </div>
              <!-- 进行中 -->
              <div v-for="(item,index) in list" :key="index" class="item">
                <p class="title">名称:{{ item.activity_name }}</p>
                <div class="center">
                  <span>发起人:{{ item.sponsor_name }}</span>
                  <span class="sign_up">报名人数:{{ item.player_count }}</span>
                  <div style="margin:10px 0;">
                    <span>讲师积分:{{ item.speaker_credits }}</span>
                    <span class="sign_up">讲师学分:{{ item.speaker_score }}</span>
                  </div>
                  <div style="margin:10px 0;">
                    <span style="margin-left:0;">员工积分:{{ item.player_credits }}</span>
                    <span class="sign_up">员工学分:{{ item.player_score }}</span>
                  </div>
                  <p class="start_time">开始时间:{{ item.start_time }}</p>
                  <p class="address">地址:{{ item.activity_space }}</p>
                </div>
                <!-- <button v-if="item.is_join === 0" class="btn bg">进行中</button>
                <button v-else class="btn bg">已报名</button> -->
                <template v-if="(item.status == 0)">
                  <!-- 未开始 -->
                  <template v-if="(item.player_count < item.max_number)||item.max_number==0">
                    <!-- 未满 -->
                    <template v-if="(item.is_join !== 0 || item.is_baoming !== 0)">
                      <!-- 已报名 -->
                      <span class="btn_gray">已报名</span>
                    </template>
                    <template v-else>
                      <!-- 未报名 -->
                      <span class="btn_red" @click="join(item)">报名</span>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 名额已满 -->
                    <span class="btn_gray">名额已满</span>
                  </template>
                </template>
                <template v-else>
                  <!-- 进行中、已结束 -->
                  <template v-if="(item.player_count < item.max_number)||item.max_number==0">
                    <!-- 未满 -->
                    <template v-if="(item.is_join === 1 || item.is_baoming === 1)">
                      <!-- 已报名 -->
                      <span v-if="(item.signin_status == 1)" class="btn_gray">已签到</span>
                      <span v-else class="btn_gray">未签到</span>
                    </template>
                    <template v-else>
                      <!-- 未报名 -->
                      <span class="btn_gray">未报名</span>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 名额已满 -->
                    <span class="btn_gray">名额已满</span>
                  </template>
                </template>
                <span class="statu_t">{{ item.status == 0 ? '活动未开始' : (item.status == 1 ? '活动进行中' : '活动已结束') }}</span>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/static/images/wu.png" alt="">
              <p>暂无数据</p>
            </div>
          </div>
        </van-tab>
        <van-tab title="以往活动">
          <div class="content">
            <div v-if="old_list.length > 0">
              <div v-for="(item,index) in old_list" :key="index" class="item">
                <p class="title">名称:{{ item.activity_name }}</p>
                <div class="center">
                  <span>发起人:{{ item.sponsor_name }}</span>
                  <span class="sign_up">报名人数:{{ item.player_count }}</span>
                  <div style="margin:10px 0;">
                    <span>讲师积分:{{ item.speaker_credits }}</span>
                    <span class="sign_up">讲师学分:{{ item.speaker_score }}</span>
                  </div>
                  <div style="margin:10px 0;">
                    <span style="margin-left:0;">员工积分:{{ item.player_credits }}</span>
                    <span class="sign_up">员工学分:{{ item.player_score }}</span>
                  </div>
                  <p class="start_time">开始时间:{{ item.start_time }}</p>
                  <p class="address">地址:{{ item.activity_space }}</p>
                </div>
                <div v-if="item.images.length" class="activity_img">
                  <p class="title">活动照片：</p>
                  <p class="img_list">
                    <img v-for="(val,key) in item.images" :key="key" class="image" :src="val" alt="">
                  </p>
                </div>
                <!-- <button v-if="item.is_join === 0" class="btn bg">已结束</button>
                <button v-else class="btn bg">已报名</button> -->
                <template v-if="(item.status == 0)">
                  <!-- 未开始 -->
                  <template v-if="(item.player_count < item.max_number)||item.max_number==0">
                    <!-- 未满 -->
                    <template v-if="(item.is_join !== 0 || item.is_baoming !== 0)">
                      <!-- 已报名 -->
                      <span class="btn_gray">已报名</span>
                    </template>
                    <template v-else>
                      <!-- 未报名 -->
                      <span class="btn_red" @click="join(item)">报名</span>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 名额已满 -->
                    <span class="btn_gray">名额已满</span>
                  </template>
                </template>
                <template v-else>
                  <!-- 进行中、已结束 -->
                  <template v-if="(item.player_count < item.max_number)||item.max_number==0">
                    <!-- 未满 -->
                    <template v-if="(item.is_join === 1 || item.is_baoming === 1)">
                      <!-- 已报名 -->
                      <span v-if="(item.signin_status == 1)" class="btn_gray">已签到</span>
                      <span v-else class="btn_gray">未签到</span>
                    </template>
                    <template v-else>
                      <!-- 未报名 -->
                      <span class="btn_gray">未报名</span>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 名额已满 -->
                    <span class="btn_gray">名额已满</span>
                  </template>
                </template>
                <span class="statu_t">{{ item.status == 0 ? '活动未开始' : (item.status == 1 ? '活动进行中' : '活动已结束') }}</span>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/static/images/wu.png" alt="">
              <p>暂无数据</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { activityList, activityJoin } from '@/api/activity.js'

export default {
  name: '',
  components: { heads },

  data() {
    return {
      title_msg: '活动',
      showMore: true,
      active: '',
      noStarList: [],
      list: [],
      old_list: []
    }
  },

  computed: {},

  created() {
    this.noStar()
    this.activity()
    this.old_activity()
  },

  mounted() {},

  methods: {
    noStar() {
      const info = {
        page: 1,
        page_size: 999,
        status: 0
      }
      activityList(info).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result || {}
          this.noStarList = result.list
        }
      })
    },
    activity() {
      const info = {
        page: 1,
        page_size: 999,
        status: 1
      }
      activityList(info).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result || {}
          this.list = result.list
        }
      })
    },
    old_activity() {
      const info = {
        page: 1,
        page_size: 999,
        status: 2
      }
      activityList(info).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result || {}
          this.old_list = result.list
        }
      })
    },
    join(item) {
      const info = {
        activity_id: item.id
      }
      activityJoin(info).then(res => {
        if (res.errNo === 0) {
          this.$toast('报名成功')
          this.noStar()
          this.activity()
          this.old_activity()
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.activity{
  .page_header{
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #ddd;
    padding: 0 25px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
        font-size: 34px;
        // font-weight: 600;
    }
    .arrow_left{
      width: 24px;
      height: 42px;
    }
  }
  .wu{
    position: absolute;
    left: 220px;
    top: 220px;
    img{
      width: 292px;
      height: 292px;
    }
    p{
      color: #999;
      margin-top: 66px;
      text-align: center;
    }
  }
  .tabs{
    height: calc(100vh - 120px);
    position: fixed;
    top: 120px;
    width: 100%;
    .h{
      height: 100px;
    }
    .content{
      padding: 0 30px;
      .item{
        margin-top: 20px;
        background: #fff;
        padding: 36px 18px;
        border-radius: 10px;
        .title{
          font-size: 30px;
          font-weight: 700;
        }
        .center{
          color: #747474;
          padding: 22px 0 33px 0;
          border-bottom: 1px solid #ddd;
          .address{
            margin-top: 15px;
          }
          .sign_up{
            margin: 0 60px 0 50px;
          }
          .integral{
            margin-left: 50px;
          }
        }
        .b_img{
          .act_img{
            margin: 33px 0 26px 0;
            font-size: 28px;
            font-weight: 700;
          }
          .imgs{
            display: inline-block;
            img{
              width: 152px;
              height: 152px;
              margin-right: 11px;
            }
            img:nth-child(4n){
              margin-right: 0;
            }
          }
        }
        .btn{
          background: #ee2e2e;
          color: #fff;
          width: 540px;
          height: 88px;
          line-height: 88px;
          text-align: center;
          border-radius: 50px;
          font-size: 32px;
          border: none;
          margin-left: 8%;
          margin-top: 42px;
        }
        .bg{
          background: #999;
        }
      }
    }
  }
}
/deep/.van-tabs{
  height: 100%;
  .van-tabs__content{
    height: calc(100% - 80px);
    overflow: scroll;
    -webkit-overflow-scrolling:touch;
     background: #f7f7f7;
  }
}
/deep/.van-tabs--line .van-tabs__wrap {
    height: 80px;
    // position: fixed;
    background: #fff;
    top: 0px;
    z-index: 999;
    width: 100%;
}

.noData {
  text-align: center;
  margin-top: 40%;
  font-size: 28px;
  color: #333;
  img {
    width: 300px;
  }
  p {
    margin-top: 20px;
  }
}
.btn_gray{
    font-size: 32px;
    text-align: center;
    // margin: 0 10px 20px 0;
    margin-top:20px;
    display: inline-block;
    width: 100%;
    height: 90px;
    line-height: 90px;
    background-color: #999;
    border-radius: 45px;
    color: white;
  }
  .btn_red{
    font-size: 32px;
    text-align: center;
    margin-top:20px;
    // margin: 0 10px 20px 0;
    display: inline-block;
    width: 100%;
    height: 90px;
    line-height: 90px;
    background-color: #ee2e2e;
    border-radius: 45px;
    color: white;
    cursor: pointer;
  }
  .statu_t{
    margin-top: 20px;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .activity_img{
    padding: 40px 0;
    .title{
      margin-bottom: 20px;
    }
    .img_list{
      display: flex;
      flex-wrap: wrap;
    }
    img{
      width: 120px;
      height: 120px;
      margin: 10px 5px;
    }
  }
</style>
