import request from '@/utils/ucenterrequest.js'
// 活动列表
export function activityList(params) {
  return request({
    url: 'activity/activity/index',
    params: params,
    method: 'get'
  })
}
// 活动报名
export function activityJoin(params) {
  return request({
    url: 'activity/activity/join',
    params: params,
    method: 'get'
  })
}

// 活动签到 验证手机号
export function activeSignIn(params) {
  return request({
    url: '/Activity/Activity/checkMobile',
    // url: '/activity/activity/checkMobile',
    method: 'get',
    params
  })
}
